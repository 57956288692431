import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,GetImgCertUser_CBack,RespBkMthdMapp,detectEmojisAndSpecialCharacters,CallMapMethod_CBack,
  getAdlogUrl} from '../function_lib/common_lib';
//import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';
import Header from '../user_pages/header';
import Footer from '../user_pages/footer';


import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';
import pdfIcon from '../assets/images/pdf_icon.png';
import mp3Icon from '../assets/images/mp3_icon.png';
import mp4Icon from '../assets/images/mp4_icon.png';

let loginUser="";
let userObject="";
let companyName="";
let clsWappCamp;


let totalFileAllow=5;

let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];


let objVideo=[];
let vidIndexExisting=0;
let arrVidExistingFiles=[];

let objPdf=[];
let pdfIndexExisting=0;
let arrPdfExistingFiles=[];

let _adlogUrl="";
class Sync_Product_Data extends React.Component{
    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={listCategory:[],stVendoList:[]};
      clsWappCamp=this;
      _adlogUrl=getAdlogUrl();
      }
     

      getCategories(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getCategoriesMaster"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_userType",value:$("#ser_userType").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"get_vendor",value:"yes"});
        //alert(status);
        /*
        if(status!=="0"){
          map.push({name:"status",value:status});
        }
        not required as of now
        */
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getCategoriesMaster";
        CallMapMethod_CBack(clsWappCamp,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                //alert(JSON.stringify(listData.vendor_list));
                clsWappCamp.setState({listCategory:listData.category_list,
                  stVendoList:listData.vendor_list},()=>{
                
                //  clsWappCamp.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }

 
     



saveWappCompaign(){
 

  
  GetImgCertUser_CBack(clsWappCamp,"#frmSyncProductMaster","updateProductStock",function(checkData,checkMsg,userMsg){
    alert(checkMsg);
    if(checkData===-10){
      alert("Kindly fill required fields");
      return;
    }else if(checkData==="001"){
      //Custom_Alert("Successfully Updated",checkMsg,true);
      $("#updResponsePro").html("File Uploaded Successfully <b></br>"+
      "<a target=\"blank\" href=\""+_adlogUrl+"_logStockUpdate"+"\\"+checkMsg+"\">click here to check log</a>")
        
    }else if(checkData==="01") {
      Custom_Alert("Not added",checkMsg,false);
      //alert(checkMsg);
    }
    
  });

  
// Custom_Alert("WAPP CAMPAIGN","<span>Test1</span><span>Test2</span>")
  
 
}

componentDidMount(){
    
this.getCategories();
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].id);


}

   render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row">
<div className="col-md-12 col-lg-12"> 
        
<section class="content">
<div class="container-fluid">
<div class="card card-secondary">
<div class="card-header">
                                <h3 class="card-title">Stock Update</h3>
                                <a className="btn btn-sm btn-danger float-right" href="../view_product_master">View Products</a>
                            </div>
                            <form class="form-group" id="frmSyncProductMaster" name="frmSyncProductMaster" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="updateProductStock" />
                            <input type="hidden" name="m_type" id="m_type" value="updateProductStock" />
                            <input type="hidden" name="wa_msg_ty_ma_id" id="wa_msg_ty_ma_id" value={"1"} />
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={userObject[0].app_user_id}/>
                          
                           
                            <div class="card-body">
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            {/* <label for="exampleInputEmail1">Vendor (if any)</label> */}
                                            <div className="input-group input-group-sm">
                                                <select id="vendor_id" name="vendor_id" className="form-control required11">
                                                  <option value="0">-Select Vendor-</option>
                                                  {this.state.stVendoList.map((item,index)=>{
                                                    return(<option value={item.id}>{item.first_name}</option>)
                                                  })}
                                                </select>
                                            </div>
                                        </div>
                                  </div>
                                    
                                </div>

                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">File upload [csv comma separated],<a href={"../stock_update.csv"}>download sample file</a></label>
                                            <div className="input-group input-group-sm123">
                                              <input type="file" id="fl_stock" name="fl_stock" className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                               
                                <div className="col-md-6 respSSL"></div>
                                <div id="updResponsePro"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>this.saveWappCompaign()} class="btn btn-primary">Update Now</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
    </div>
    </div>
</section>
      
</div>
    
</div>
</div>
</div>
<Alert_Content/>
<Footer/>

          </div>)
      }
}
export default Sync_Product_Data;